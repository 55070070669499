import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SampleNewsletter } from "@/model/general";
import { SAMPLE_NEWSLETTERS } from "@/util/newsletter";

interface NewsletterProps {
  sampleNewsletter: SampleNewsletter;
}
const initialState: NewsletterProps = {
  sampleNewsletter: SAMPLE_NEWSLETTERS[0],
};

export const NewsletterSlice = createSlice({
  initialState,
  name: "siteSlice",
  reducers: {
    SetSampleNewsletter: (state, action: PayloadAction<SampleNewsletter>) => {
      state.sampleNewsletter = action.payload;
    },
  },
});

export const { SetSampleNewsletter } = NewsletterSlice.actions;
export default NewsletterSlice.reducer;
