import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  TourIndex,
  TourLength,
  TourPageValue,
  TourProps,
  TypeString,
} from "@/model";
import { ProductTour, ProductTourInitialState } from "@/model/productTour";
import { initialTourObject } from "@/util/tourObject";

const initialState: ProductTourInitialState = {
  ProductTour: initialTourObject,
};

export const TourSlice = createSlice({
  initialState,
  name: "tourSlice",
  reducers: {
    SetNavProductTour: (state, action: PayloadAction<TourIndex>) => {
      const page = action.payload.page as keyof ProductTour;
      state.ProductTour[page].stateProps.stepIndex = action.payload.tourIndex;
    },
    SetProductTour: (state, action: PayloadAction<ProductTourInitialState>) => {
      state.ProductTour = action.payload.ProductTour;
    },
    SetProductTourCompleted: (state, action: PayloadAction<TourPageValue>) => {
      const page = action.payload.page as keyof ProductTour;
      state.ProductTour[page].completed = action.payload.value;
    },
    SetProductTourLength: (state, action: PayloadAction<TourLength>) => {
      const page = action.payload.page as keyof ProductTour;
      state.ProductTour[page].tourLength = action.payload.tourLength;
    },
    SetProductTourModalVisibility: (
      state,
      action: PayloadAction<TourPageValue>
    ) => {
      const page = action.payload.page as keyof ProductTour;
      state.ProductTour[page].modalShown = action.payload.value;
    },
    SetRestartProductTour: (state, action: PayloadAction<TourProps>) => {
      const page = action.payload.page as keyof ProductTour;
      state.ProductTour[page].stateProps = {
        ...state.ProductTour[page].stateProps,
        ...action.payload.payload,
      };
    },
    SetStartProductTour: (state, action: PayloadAction<TypeString>) => {
      const page = action.payload.page as keyof ProductTour;
      state.ProductTour[page].stateProps.run = true;
    },
    SetStopProductTour: (state, action: PayloadAction<TypeString>) => {
      const page = action.payload.page as keyof ProductTour;
      state.ProductTour[page].stateProps.run = false;
    },
  },
});

export const {
  SetNavProductTour,
  SetProductTour,
  SetRestartProductTour,
  SetStartProductTour,
  SetStopProductTour,
  SetProductTourModalVisibility,
  SetProductTourCompleted,
  SetProductTourLength,
} = TourSlice.actions;
export default TourSlice.reducer;
