import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GetCustomer } from "@/model/api";

export interface UserSlice extends UserSliceAddressFragment {
  activeSub: boolean;
  email_address: string;
  first_name: string;
  id: string;
  isStaff: boolean;
  last_name: string;
  login_provider_name: string;
  phone_number: string;
  profileCompleted: boolean;
  profileLoaded: boolean;
  profileUpdateInProgress: boolean;
  public_id: string;
  stateAsyncLoaded: boolean;
  subLoaded: boolean;
  tos_effective_date: string;
  tos_required: boolean;
  validatedAccount: boolean;
}

export interface UserSliceAddressFragment {
  city: string;
  country: string;
  line1: string;
  line2: string;
  line3: string;
  state: string;
  zipcode: string;
}

const initialState: UserSlice = {
  activeSub: false,
  city: "",
  country: "",
  email_address: "",
  first_name: "",
  id: "",
  isStaff: false,
  last_name: "",
  line1: "",
  line2: "",
  line3: "",
  login_provider_name: "",
  phone_number: "",
  profileCompleted: false,
  profileLoaded: false,
  profileUpdateInProgress: false,
  public_id: "",
  state: "",
  stateAsyncLoaded: false,
  subLoaded: false,
  tos_effective_date: "",
  tos_required: false,
  validatedAccount: false,
  zipcode: "",
};

export const UserSlice = createSlice({
  initialState,
  name: "userSlice",
  reducers: {
    clearUser: (state) => {
      state = initialState;
    },
    setAccount: (state, action: PayloadAction<Partial<GetCustomer>>) => {
      return { ...state, ...action.payload, profileCompleted: true };
    },
    setActiveSub: (state, action: PayloadAction<boolean>) => {
      state.activeSub = action.payload;
    },
    setIsStaff: (state, action: PayloadAction<boolean>) => {
      state.isStaff = action.payload;
    },
    setProfileLoaded: (state) => {
      state.profileLoaded = true;
    },
    setProfileUpdateInProgress: (state, action: PayloadAction<boolean>) => {
      state.profileUpdateInProgress = action.payload;
    },
    setStateAsyncLoaded: (state) => {
      state.stateAsyncLoaded = true;
    },
    setSubLoaded: (state) => {
      state.subLoaded = true;
    },
    setValidatedAccount: (state, action: PayloadAction<boolean>) => {
      state.validatedAccount = action.payload;
    },
  },
});

export const {
  setStateAsyncLoaded,
  setAccount,
  setActiveSub,
  setValidatedAccount,
  setProfileLoaded,
  setSubLoaded,
  setProfileUpdateInProgress,
  setIsStaff,
  clearUser,
} = UserSlice.actions;
export default UserSlice.reducer;
