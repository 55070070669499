import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { NarrativeSummary, TopNarratives } from "@/model/api";

interface ArticlePageProps {
  companyDescription: string | null;
  companyDescriptionLoading: boolean;
  historicalNarratives: NarrativeSummary[];
  historicalNarrativesLoading: boolean;
  narrativeSummaries: TopNarratives;
  narrativeSummariesLoading: boolean;
}

const initialState: ArticlePageProps = {
  companyDescription: null,
  companyDescriptionLoading: true,
  historicalNarratives: [],
  historicalNarrativesLoading: true,
  narrativeSummaries: {
    TopPrimary: [],
    TopSecondary: [],
    TopWeekly: [],
    Trending: [],
  },
  narrativeSummariesLoading: true,
};

export const ArticleSlice = createSlice({
  initialState,
  name: "ArticleSlice",
  reducers: {
    SetCompanyDescription: (
      state,
      action: PayloadAction<{ description: string; loading: boolean }>
    ) => {
      state.companyDescription = action.payload.description;
      state.companyDescriptionLoading = action.payload.loading;
    },
    SetHistoricalNarratives: (
      state,
      action: PayloadAction<NarrativeSummary[]>
    ) => {
      state.historicalNarratives = action.payload;
    },
    SetHistoricalNarrativesLoading: (state, action: PayloadAction<boolean>) => {
      state.historicalNarrativesLoading = action.payload;
    },
    SetNarrativeSummaries: (
      state,
      action: PayloadAction<{
        data: TopNarratives;
        loading: boolean;
      }>
    ) => {
      state.narrativeSummaries = action.payload.data;
      state.narrativeSummariesLoading = action.payload.loading;
    },
  },
});

export const {
  SetCompanyDescription,
  SetHistoricalNarratives,
  SetHistoricalNarrativesLoading,
  SetNarrativeSummaries,
} = ArticleSlice.actions;
export default ArticleSlice.reducer;
