import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  FlattenArticle,
  ListHomepageNewsCoverage,
  ListHomepageNewsCoverageInitialState,
} from "@/model/homepage";

const initialState: ListHomepageNewsCoverageInitialState = {
  list: [] as FlattenArticle[],
};

export const HomepageSlice = createSlice({
  initialState,
  name: "HomepageSlice",
  reducers: {
    SetList: (state, action: PayloadAction<ListHomepageNewsCoverage[]>) => {
      const news = action.payload;
      const newsList = news?.map((newsInfo: ListHomepageNewsCoverage) => {
        const newsArticles = {
          id: newsInfo.id,
          name: newsInfo.name,
          recommendedActions: [...newsInfo.recommendedActions],
          stockClosePrices: [...newsInfo.stockClosePrices],
          ticker: newsInfo.ticker,
        };
        return { ...newsInfo.articles[0], ...newsArticles };
      });

      const sortArticles = newsList?.flat().sort(function (a: any, b: any) {
        return (
          Number(new Date(b.articlePublishedOn)) -
          Number(new Date(a.articlePublishedOn))
        );
      });
      state.list = sortArticles;
    },
  },
});

export const { SetList } = HomepageSlice.actions;
export default HomepageSlice.reducer;
