import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PriceRange } from "@/model";
import { HomeInitialState } from "@/model/home";

const initialState: HomeInitialState = {
  dateSelected: 7,
  stockRangeFilter: {
    MaxPrice: null,
    MinPrice: null,
  } as PriceRange,
};

export const HomeSlice = createSlice({
  initialState,
  name: "homeSlice",
  reducers: {
    SetDateSelected: (state, action: PayloadAction<number>) => {
      state.dateSelected = action.payload;
    },
    SetStockRangeFilter: (state, action: PayloadAction<PriceRange>) => {
      state.stockRangeFilter = action.payload;
    },
  },
});

export const { SetDateSelected, SetStockRangeFilter } = HomeSlice.actions;
export default HomeSlice.reducer;
