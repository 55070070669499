import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Subscription } from "@/model/api";

export interface SubSlice {
  sub: Subscription | undefined;
}

const initialState: SubSlice = { sub: undefined };

export const SubSlice = createSlice({
  initialState,
  name: "subSlice",
  reducers: {
    setSub: (state, action: PayloadAction<Subscription>) => {
      state.sub = action.payload;
    },
  },
});

export const { setSub } = SubSlice.actions;
export default SubSlice.reducer;
