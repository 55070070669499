import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SnapshotData } from "@/model/api";
import { SnapshotInitialState } from "@/model/snapshot";

const initialState: SnapshotInitialState = {
  premiumUpsellEnabled: false,
  snapshotData: undefined,
  snapshotSelectedCompany: undefined,
};

export const SnapshotSlice = createSlice({
  initialState,
  name: "snapshotSlice",
  reducers: {
    SetPremiumUpsellEnabled: (state, action: PayloadAction<boolean>) => {
      state.premiumUpsellEnabled = action.payload;
    },
    SetSnapshotData: (
      state,
      action: PayloadAction<SnapshotData | undefined>
    ) => {
      state.snapshotData = action.payload;
    },
    SetSnapshotSelectedCompany: (state, action: PayloadAction<string>) => {
      state.snapshotSelectedCompany = action.payload;
    },
  },
});

export const {
  SetPremiumUpsellEnabled,
  SetSnapshotData,
  SetSnapshotSelectedCompany,
} = SnapshotSlice.actions;
export default SnapshotSlice.reducer;
