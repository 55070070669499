import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ApplauseLog, SiteInitialState, ToolboxTab } from "@/model/site";

const initialState: SiteInitialState = {
  applauseLogs: [],
};

export const SiteSlice = createSlice({
  initialState,
  name: "siteSlice",
  reducers: {
    AddApplauseLog: (state, action: PayloadAction<ApplauseLog>) => {
      state.applauseLogs = [...state.applauseLogs, action.payload];
    },
    ClearApplauseLog: (state) => {
      state.applauseLogs = [];
    },
    SetDevToolboxTab: (
      state,
      action: PayloadAction<ToolboxTab | undefined>
    ) => {
      state.devToolboxTab = action.payload;
    },
  },
});

export const { AddApplauseLog, ClearApplauseLog, SetDevToolboxTab } =
  SiteSlice.actions;
export default SiteSlice.reducer;
