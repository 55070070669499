import dayjs from "./dayjs";

// For EST (UTC -5) date calculation, common for all
export const getCurrentDate = () => dayjs().utc().utcOffset(-5);

export const getPastDate = (noOfDays: number = 0) =>
  getCurrentDate().subtract(noOfDays, "days");

export const getPastDateString = (noOfDays: number = 0) => {
  return getPastDate(noOfDays).format("YYYY-MM-DD");
};

export const getFutureDate = (noOfDays: number = 0) =>
  getCurrentDate().add(noOfDays, "days");

export const getFutureDateString = (noOfDays: number = 0) => {
  return getFutureDate(noOfDays).format("YYYY-MM-DD");
};

export const getDateSeconds = () => {
  const startDate = new Date("2022-01-01");
  const endDate = new Date();
  const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
  return Math.abs(seconds);
};
