import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SELECTED_PORTFOLIO } from "@/constants";
import { BaseAction, Horizon } from "@/model";
import {
  Portfolio,
  PortfolioCompany,
  SectorPortfolioHealthFactorValues,
} from "@/model/api";
import { PortfolioInitialState } from "@/model/portfolio";
import { SelectedPortfolio } from "@/model/portfolio";

const initialState: PortfolioInitialState = {
  HfFilter: {
    HF: null,
    value: null,
  },
  actionFilter: {
    Action: ["Buy +", "Buy -", "Sell +", "Sell -", "Watch +", "Watch -"],
    Horizon: "1 week",
  },
  companyAddedToPortfolio: 0,
  companyList: [],
  companyListTabIndex: 0,
  pinnedPortfolio: SELECTED_PORTFOLIO,
  portfolioCompanies: [],
  portfolios: [],
  selectedPortfolio: SELECTED_PORTFOLIO,
};

export const PortfolioSlice = createSlice({
  initialState,
  name: "portfolioSlice",
  reducers: {
    SetActionFilter: (
      state,
      action: PayloadAction<{
        Action: BaseAction[];
        Horizon: Horizon;
      }>
    ) => {
      state.actionFilter.Horizon = action.payload.Horizon;
      state.actionFilter.Action = action.payload.Action;
    },
    SetActionFilterHorizon: (state, action: PayloadAction<Horizon>) => {
      state.actionFilter.Horizon = action.payload;
    },
    SetClearData: (state) => {
      state.selectedPortfolio = SELECTED_PORTFOLIO;
      state.portfolioCompanies = [];
    },
    SetCompanies: (state, action: PayloadAction<PortfolioCompany[]>) => {
      state.portfolioCompanies = action.payload;
    },
    SetCompanyAddedReset: (state) => {
      state.companyAddedToPortfolio = 0;
    },

    SetCompanyAddedToPortfolio: (state) => {
      state.companyAddedToPortfolio += 1;
    },
    SetCompanyList: (state, action: PayloadAction<string[]>) => {
      state.companyList = action.payload;
    },
    SetCompanyListTabIndex: (state, action: PayloadAction<number>) => {
      state.companyListTabIndex = action.payload;
    },
    SetHfFilter: (
      state,
      action: PayloadAction<{
        HF: keyof SectorPortfolioHealthFactorValues | null;
        value: string | null;
      }>
    ) => {
      state.HfFilter.HF = action.payload.HF;
      state.HfFilter.value = action.payload.value;
    },
    SetInitialPortfolio: (
      state,
      action: PayloadAction<{
        defaultPortfolio: Portfolio;
        portfolios: Portfolio[];
      }>
    ) => {
      state.portfolios = action.payload.portfolios;
      // state.selectedPortfolio = action.payload.defaultPortfolio;
      state.pinnedPortfolio = action.payload.defaultPortfolio;
      state.portfolioCompanies = [];
    },
    SetPinnedPortfolio: (state, action: PayloadAction<SelectedPortfolio>) => {
      // state.selectedPortfolio = action.payload;
      state.pinnedPortfolio = action.payload;
    },
    SetPortfolios: (state, action: PayloadAction<Portfolio[]>) => {
      state.portfolios = action.payload;
    },
    SetSelectedPortfolio: (state, action: PayloadAction<SelectedPortfolio>) => {
      if (action.payload) {
        state.selectedPortfolio.GroupId = action.payload.GroupId;
        state.selectedPortfolio.Id = action.payload.Id;
        state.selectedPortfolio.Name = action.payload.Name;
        state.portfolioCompanies = [];
      }
    },
  },
});

export const {
  SetInitialPortfolio,
  SetPortfolios,
  SetSelectedPortfolio,
  SetPinnedPortfolio,
  SetCompanies,
  SetCompanyAddedToPortfolio,
  SetCompanyAddedReset,
  SetActionFilter,
  SetCompanyList,
  SetCompanyListTabIndex,
  SetHfFilter,
  SetActionFilterHorizon,
  SetClearData,
} = PortfolioSlice.actions;
export default PortfolioSlice.reducer;
