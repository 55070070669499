import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LeaderBoardInitialState } from "@/model/leaderBoard";
import { Entity, Types } from "@/model/leaderBoard";

const initialState: LeaderBoardInitialState = {
  dateSelected: 5,
  entity: null,
  type: null,
};

export const LeaderboardSlice = createSlice({
  initialState,
  name: "leaderBoardSlice",
  reducers: {
    SetDateSelected: (state, action: PayloadAction<number>) => {
      state.dateSelected = action.payload;
    },
    SetTabs: (
      state,
      action: PayloadAction<{ entity: Entity; type: Types }>
    ) => {
      state.type = action.payload.type;
      state.entity = action.payload.entity;
    },
  },
});

export const { SetDateSelected, SetTabs } = LeaderboardSlice.actions;
export default LeaderboardSlice.reducer;
