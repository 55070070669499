import { AnyAction, configureStore, Store } from "@reduxjs/toolkit";
import { Provider } from "react-redux";

import ArticleSlice from "@/slice/ArticleSlice";
import companyProfileSlice from "@/slice/CompanyProfileSlice";
import HomepageSlice from "@/slice/HomepageSlice";
import HomeSlice from "@/slice/HomeSlice";
import LeaderboardSlice from "@/slice/LeaderboardSlice";
import NewsletterSlice from "@/slice/NewsletterSlice";
import PortfolioSlice from "@/slice/PortfolioSlice";
import SectorSlice from "@/slice/SectorSlice";
import SettingsSlice from "@/slice/SettingsSlice";
import SiteSlice from "@/slice/SiteSlice";
import SnapshotSlice from "@/slice/SnapshotSlice";
import subSlice from "@/slice/SubSlice";
import TourSlice from "@/slice/TourSlice";
import userSlice from "@/slice/UserSlice";

export const reduxStore = configureStore({
  reducer: {
    articles: ArticleSlice,
    companyProfile: companyProfileSlice,
    home: HomeSlice,
    homepage: HomepageSlice,
    leaderBoard: LeaderboardSlice,
    newsletter: NewsletterSlice,
    portfolio: PortfolioSlice,
    productTour: TourSlice,
    sector: SectorSlice,
    settings: SettingsSlice,
    site: SiteSlice,
    snapshot: SnapshotSlice,
    sub: subSlice,
    user: userSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;

export interface ReduxStoreProps {
  children: React.ReactNode;
  customStore?: Store<any, AnyAction>;
}

const ReduxStore = ({ customStore, children }: ReduxStoreProps) => (
  <Provider store={customStore || reduxStore}>{children}</Provider>
);

export default ReduxStore;
