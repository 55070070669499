import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LV1_HEALTH_FACTORS, Lv1HealthFactor, Sector } from "@/constants";
import { BaseAction, Horizon } from "@/model";
import {
  HealthScore,
  RankedPredictions,
  SectorPortfolioHealthFactorValues,
} from "@/model/api";
import { SectorCompany, SectorInitialState } from "@/model/sector";

const initialState: SectorInitialState = {
  HfFilter: {
    HF: null,
    value: null,
  },
  actionFilter: {
    Action: ["Buy +", "Buy -", "Sell +", "Sell -", "Watch +", "Watch -"],
    Horizon: "1 week",
  },
  companyListTabIndex: 0,
  healthFactors: [],
  healthFactorsFilter: LV1_HEALTH_FACTORS,
  name: null,
  sectorCompanies: [],
  sectorRankedPredictions: [],
  sliderValue: 1,
};

export const SectorSlice = createSlice({
  initialState,
  name: "sectorSlice",
  reducers: {
    SetActionFilter: (
      state,
      action: PayloadAction<{
        Action: BaseAction[];
        Horizon: Horizon;
      }>
    ) => {
      state.actionFilter.Horizon = action.payload.Horizon;
      state.actionFilter.Action = action.payload.Action;
    },
    SetActionFilterHorizon: (state, action: PayloadAction<Horizon>) => {
      state.actionFilter.Horizon = action.payload;
    },
    SetCompanies: (state, action: PayloadAction<SectorCompany[]>) => {
      state.sectorCompanies = action.payload;
    },
    SetCompanyListTabIndex: (state, action: PayloadAction<number>) => {
      state.companyListTabIndex = action.payload;
    },
    SetHealthFactorFilters: (
      state,
      action: PayloadAction<Lv1HealthFactor[]>
    ) => {
      state.healthFactorsFilter = action.payload;
    },
    SetHealthFactors: (state, action: PayloadAction<HealthScore[]>) => {
      state.healthFactors = action.payload;
    },
    SetHfFilter: (
      state,
      action: PayloadAction<{
        HF: keyof SectorPortfolioHealthFactorValues | null;
        value: string | null;
      }>
    ) => {
      state.HfFilter.HF = action.payload.HF;
      state.HfFilter.value = action.payload.value;
    },
    SetSector: (state, action: PayloadAction<Sector | null>) => {
      state.name = action.payload;
      state.sectorCompanies = [];
    },
    SetSectorRankedPredictions: (
      state,
      action: PayloadAction<RankedPredictions[]>
    ) => {
      state.sectorRankedPredictions = action.payload;
    },
    SetSliderValue: (state, action: PayloadAction<number>) => {
      state.sliderValue = action.payload;
    },
  },
});

export const {
  SetSector,
  SetHealthFactorFilters,
  SetHealthFactors,
  SetSectorRankedPredictions,
  SetSliderValue,
  SetActionFilter,
  SetCompanies,
  SetCompanyListTabIndex,
  SetHfFilter,
  SetActionFilterHorizon,
} = SectorSlice.actions;
export default SectorSlice.reducer;
