import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SettingsInitialState } from "@/model/settings";

const initialState: SettingsInitialState = {
  LeaderboardModalShown: false,
  OverviewVideoWatched: undefined,
  PinnedPortfolioId: null,
  SettingsLoaded: false,
};

export const SettingsSlice = createSlice({
  initialState,
  name: "settingsSlice",
  reducers: {
    SetLeaderboardModalShown: (state, action: PayloadAction<boolean>) => {
      state.LeaderboardModalShown = action.payload;
    },
    SetOverviewVideoWatched: (
      state,
      action: PayloadAction<SettingsInitialState>
    ) => {
      state.SettingsLoaded = true;
      state.OverviewVideoWatched = action.payload.OverviewVideoWatched;
    },
    SetUserSettings: (state, action: PayloadAction<SettingsInitialState>) => {
      state.SettingsLoaded = true;
      state.OverviewVideoWatched = action.payload.OverviewVideoWatched;
      state.PinnedPortfolioId = action.payload.PinnedPortfolioId ?? null;
    },
  },
});

export const {
  SetUserSettings,
  SetOverviewVideoWatched,
  SetLeaderboardModalShown,
} = SettingsSlice.actions;
export default SettingsSlice.reducer;
